import { Endpoints } from "./../config/endpoints"
import { PublicClientApplication } from "@azure/msal-browser"
import toast from "react-hot-toast"
import useJwt from "@src/auth/jwt/useJwt"

export async function login(data) {
  return await useJwt
    .post(Endpoints.login, data)
    .then((res) => res)
    .catch((err) => err)
}

export const LogintoAzure = async () => {
  const PCInstance = new PublicClientApplication({
    auth: {
      clientId: process.env.REACT_APP_CLIENT_ID,
      authority: process.env.REACT_APP_AUTHORITY,
      redirectUri: process.env.REACT_APP_REDIRECT_URI
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: true
    },
    system: {
      pollIntervalMilliseconds: 15
    }
  })

  const authCodeRequest = {
    scopes: ["user.read"],
    redirectUri: process.env.REACT_APP_REDIRECT_URI
  }

  await PCInstance.initialize()

  return await PCInstance.loginPopup(authCodeRequest)
    .then((response) => response)
    .catch((error) => error)
}

export async function register(data) {
  return await useJwt
    .post(Endpoints.register, data)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function verifyEmail(data) {
  return await useJwt
    .post(Endpoints.verifyEmail, data)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function resendOtp(data) {
  return await useJwt
    .post(Endpoints.resendOtp, data)
    .then((res) => res)
    .catch((err) => err)
}

export async function forgotPassword(data) {
  return await useJwt
    .post(Endpoints.forgotPassword, data)
    .then((res) => res)
    .catch((err) => err)
}

export async function resetPasswordTokenVerify(data) {
  return await useJwt
    .get(`${Endpoints.resetPassword}/${data.token}`)
    .then((res) => res)
    .catch((err) => err)
}

export async function resetPassword(data) {
  return await useJwt
    .post(`${Endpoints.resetPassword}/${data.token}`, {
      password: `${data.password}`
    })
    .then((res) => res)
    .catch((err) => err)
}

export async function getAllJobs(data) {
  let params = ""
  if (data) {
    const { perPage, page, job_title, job_category, status, dateRange } = data

    params += perPage ? `perPage=${perPage}` : ""
    params += page ? `&page=${page}` : ""
    params += job_title ? `&job_title=${job_title}` : ""
    params += job_category ? `&job_category=${job_category}` : ""
    params += status ? `&status=${status}` : ""
    params += dateRange ? `&dateRange=${dateRange}` : ""
    params = params ? `?${params}` : ""
  }
  return await useJwt
    .get(`${Endpoints.getAllJobs}${params}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function ChangeJobVisibilityStatus(data) {
  return await useJwt
    .patch(`${Endpoints.getAllJobs}/${data.params}/changeJobStatus`, data.body)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function getSpecificJob(data) {
  return await useJwt
    .get(`${Endpoints.getAllJobs}/${data.params}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function getAppliedJobs(data) {
  let params = ""
  if (data) {
    const { perPage, page } = data
    params += perPage ? `perPage=${perPage}` : ""
    params += page ? `&page=${page}` : ""
    params += data.fullName ? `&fullName=${data.fullName}` : ""
    params += data.jobCategory ? `&jobCategory=${data.jobCategory}` : ""
    params += data.applyDateRange
      ? `&applyDateRange=${data.applyDateRange}`
      : ""
    params += data.jobApplicationStatus
      ? `&jobApplicationStatus=${data.jobApplicationStatus}`
      : ""

    params = params ? `?${params}` : ""
  }
  return await useJwt
    .get(`${Endpoints.getAppliedJobs}${params}`)
    // .get(`${Endpoints.getSpecificApplicant}${params}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function getAppliedJobsByApplicants(data) {
  let params = ""
  if (data) {
    const { perPage, page } = data
    params += perPage ? `perPage=${perPage}` : ""
    params += page ? `&page=${page}` : ""
    params += data.fullName ? `&fullName=${data.fullName}` : ""
    params += data.jobCategory ? `&jobCategory=${data.jobCategory}` : ""
    params += data.applyDateRange
      ? `&applyDateRange=${data.applyDateRange}`
      : ""
    params += data.jobApplicationStatus
      ? `&jobApplicationStatus=${data.jobApplicationStatus}`
      : ""

    params = params ? `?${params}` : ""
  }
  return await useJwt
    .get(`${Endpoints.getSpecificApplicant}${params}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function getSpecificAppliedJobsByApplicants(data) {
  return await useJwt
    .get(`${Endpoints.getSpecificApplicant}/specific/${data.params}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function getSpecificApplicantSpecificJob(data) {
  return await useJwt
    .get(`${Endpoints.getSpecificApplicant}/specific/${data.params}`)
    .then((res) => res.data)
    .catch((err) => err)
}

// GET SPECIFIC APPLICANTS ALL JOBS

export async function getSpecificAppliedJob(data) {
  return await useJwt
    .get(`${Endpoints.getAppliedJobs}/specific/${data.params}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function applyForJob(data) {
  return await useJwt
    .post(Endpoints.getSpecificApplicant, data)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function getAllCountries() {
  return await useJwt
    .get(Endpoints.getAllCountries, { useBaseUrl: false })
    .then((res) => res.data)
    .catch((err) => err)
}

export async function getAllStates(data) {
  return await useJwt
    .post(Endpoints.getAllStates, data, { useBaseUrl: false })
    .then((res) => res.data)
    .catch((err) => err)
}

export async function getAllCities(data) {
  return await useJwt
    .post(Endpoints.getAllCities, data, { useBaseUrl: false })
    .then((res) => res.data)
    .catch((err) => err)
}

// function to upload resume file to server using axios as form data when key is cv and value is file
export async function uploadResume(data) {
  const formData = new FormData()
  formData.append("cv", data)
  return await useJwt
    .post(Endpoints.uploadResume, formData)
    .then((res) => res.data)
    .catch((err) => err)
}

// function to create profile data
export async function createProfile(data) {
  return await useJwt
    .post(Endpoints.createProfile, data)
    .then((res) => res)
    .catch((err) => err)
}

// function to get skills data
export async function getSkills() {
  return await useJwt
    .get(Endpoints.getSkills)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function getAllJobCategories(data) {
  let params = ""
  if (data) {
    const { perPage, page } = data
    params += perPage ? `perPage=${perPage}` : ""
    params += page ? `&page=${page}` : ""
    params = params ? `?${params}` : ""
  }
  return await useJwt
    .get(`${Endpoints.jobCategories}${params}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function createJobCategory(data) {
  return await useJwt
    .post(Endpoints.jobCategories, data)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function updateJobCategory(data) {
  return await useJwt
    .patch(`${Endpoints.jobCategories}/${data.params}`, data.body)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function deleteJobCategory(data) {
  return await useJwt
    .delete(`${Endpoints.jobCategories}/${data.params}`)
    .then((res) => res.data)
    .catch((err) => err)
}
// post profile lnguages
export async function postProfileLanguages(data) {
  return await useJwt
    .post(Endpoints.getProfileLanguages, data)
    .then((res) => res.data)
    .catch((err) => err)
}
// post profile Education
export async function postProfileEducation(data) {
  return await useJwt
    .post(Endpoints.getProfileEducation, data)
    .then((res) => res.data)
    .catch((err) => err)
}
// post profile Experience
export async function postProfileExperience(data) {
  return await useJwt
    .post(Endpoints.getProfileExperience, data)
    .then((res) => res.data)
    .catch((err) => err)
}
// post profile Skillsets
export async function postProfileSkillsets(data) {
  return await useJwt
    // .post(Endpoints.getProfileSkillsets, data)
    .post(
      `${Endpoints.getProfileSkillsets}/${data.seekerId}/seeker_skills`,
      data.body
    )
    .then((res) => res.data)
    .catch((err) => err)
}
// delete skillset
export async function deleteSkillset(data) {
  return await useJwt
    .delete(
      `${Endpoints.getProfileSkillsets}/${data.seekerId}/seeker_skills/${data.params}`
    )
    .then((res) => res.data)
    .catch((err) => err)
}

// function to get profile data
export async function getProfile(data) {
  return await useJwt
    .get(`${Endpoints.getProfile}/${data.params}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function patchProfile(data) {
  return await useJwt
    .patch(`${Endpoints.getProfile}/${data.params}`, data.body)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function getProfileExperience(data) {
  return await useJwt
    .get(`${Endpoints.getProfileExperience}/${data.params}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function getProfileEducation(data) {
  return await useJwt
    .get(`${Endpoints.getProfileEducation}/${data.params}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function getProfileSkillsets(data) {
  return await useJwt
    .get(`${Endpoints.getProfileSkillsets}/${data.params}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function getProfileLanguages(data) {
  return await useJwt
    .get(`${Endpoints.getProfileLanguages}/${data.params}`)
    .then((res) => res.data)
    .catch((err) => err)
}
// update experience
export async function updateProfileExperience(data) {
  return await useJwt
    .patch(`${Endpoints.getProfileExperience}/${data.params}`, data.body)
    .then((res) => res.data)
    .catch((err) => err)
}
// update education
export async function updateProfileEducation(data) {
  return await useJwt
    .patch(`${Endpoints.getProfileEducation}/${data.params}`, data.body)
    .then((res) => res.data)
    .catch((err) => err)
}

// update skillset
export async function updateProfileSkillset(data) {
  return await useJwt
    .patch(
      `${Endpoints.getProfileSkillsets}/${data.seekerId}/seeker_skills/${data.params}`,
      data.body
    )
    .then((res) => res.data)
    .catch((err) => err)
}

// update language
export async function updateProfileLanguage(data) {
  return await useJwt
    .patch(`${Endpoints.getProfileLanguages}/${data.params}`, data.body)
    .then((res) => res.data)
    .catch((err) => err)
}

// delete
export async function deleteProfileExperience(data) {
  return await useJwt
    .delete(`${Endpoints.getProfileExperience}/${data.params}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function deleteProfileEducation(data) {
  return await useJwt
    .delete(`${Endpoints.getProfileEducation}/${data.params}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function deleteProfileLanguage(data) {
  return await useJwt
    .delete(`${Endpoints.getProfileLanguages}/${data.params}`)
    .then((res) => res.data)
    .catch((err) => err)
}

// delete skillset
export async function deleteProfileSkillset(data) {
  return await useJwt
    .delete(`${Endpoints.getProfileSkillsets}/${data.params}`)
    .then((res) => res.data)
    .catch((err) => err)
}

// company profile
export async function companyProfile(data) {
  return await useJwt
    .get(`${Endpoints.companyProfile}/specific/${data.params}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function getCompanyLocations() {
  return await useJwt
    .get(`${Endpoints.companyProfileLocation}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function createCompanyLocation(data) {
  return await useJwt
    .post(`${Endpoints.companyProfileLocation}`, data)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function updateCompanyLocation(data) {
  return await useJwt
    .patch(
      `${Endpoints.companyProfileLocation}/specific/${data.params}`,
      data.body
    )
    .then((res) => res.data)
    .catch((err) => err)
}

export async function deleteCompanyLocation(data) {
  return await useJwt
    .delete(`${Endpoints.companyProfileLocation}/specific/${data.params}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function updateProfile(data) {
  return await useJwt
    .patch(`${Endpoints.companyProfile}/specific/${data.params}`, data.body)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function postJob(data) {
  return await useJwt
    .post(Endpoints.getAllJobs, data)
    .then((res) => res.data)
    .catch((err) => err)
}

// get all Skills
export async function getAllSkills(data) {
  let params = ""
  if (data) {
    const { perPage, page } = data
    params += perPage ? `perPage=${perPage}` : ""
    params += page ? `&page=${page}` : ""
    params = params ? `?${params}` : ""
  }
  return await useJwt
    .get(`${Endpoints.Skills}${params}`)
    .then((res) => res.data)
    .catch((err) => err)
}

// create skill
export async function createSkill(data) {
  return await useJwt
    .post(Endpoints.Skills, data)
    .then((res) => res.data)
    .catch((err) => err)
}

// update skill
export async function updateSkill(data) {
  return await useJwt
    .patch(`${Endpoints.Skills}/${data.params}`, data.body)
    .then((res) => res.data)
    .catch((err) => err)
}

// delete skill
export async function deleteSkill(data) {
  return await useJwt
    .delete(`${Endpoints.Skills}/${data.params}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function generatejobDescription(data) {
  return await useJwt
    .post(Endpoints.generateJobDescription, data)
    .then((res) => res)
    .catch((err) => err)
}

export async function getApplicantsInfo() {
  return await useJwt
    .get(`${Endpoints.getApplicantsInfo}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function createOAuthKey(data) {
  return await useJwt
    .post(`${Endpoints.oAuthGenerate}`, data)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function getAllOAuthKeys() {
  return await useJwt
    .get(`${Endpoints.oAuthGenerate}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function changeOAuthKeyStatus(data) {
  return await useJwt
    .patch(`${Endpoints.oAuthGenerate}/specific/${data.params}`, data.body)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function changePassword(data) {
  return await useJwt
    .put(`${Endpoints.changePassword}`, data)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function uploadCompanyLogo(data) {
  const formData = new FormData()
  formData.append("companyLogo", data)
  return await useJwt
    .post(Endpoints.companyLogo, formData)
    .then((res) => res.data)
    .catch((err) => err)
}

// Get All Groups
export async function getAllGroups() {
  return await useJwt
    .get(`${Endpoints.getAllGroups}`)
    .then((res) => res.data)
    .catch((err) => err)
}

// Get Specific Group (all modules)
export async function getGroup(data) {
  return await useJwt
    .get(`${Endpoints.getAllGroups}/specific/${data.group}`)
    .then((res) => res.data)
    .catch((err) => err)
}

// get Specific Module (all features) inside Specific Group
export async function getModule(data) {
  return await useJwt
    .get(
      `${Endpoints.getAllGroups}/specific/${data.group}/module/${data.module}`
    )
    .then((res) => res.data)
    .catch((err) => err)
}

// get Specific Feature inside Specific Module inside Specific Group
export async function getFeature(data) {
  return await useJwt
    .get(
      `${Endpoints.getAllGroups}/specific/${data.group}/module/${data.module}/feature/${data.feature}`
    )
    .then((res) => res.data)
    .catch((err) => err)
}

export async function updateFeatureStatus(data) {
  return await useJwt
    .patch(
      `${Endpoints.getAllGroups}/specific/${data.group}/module/${data.module}/feature/${data.feature}`,
      data.body
    )
    .then((res) => res.data)
    .catch((err) => err)
}

export async function updateModuleStatus(data) {
  return await useJwt
    .patch(
      `${Endpoints.getAllGroups}/specific/${data.group}/module/${data.module}`,
      data.body
    )
    .then((res) => res.data)
    .catch((err) => err)
}

export async function listOfGroups() {
  return await useJwt
    .get(`${Endpoints.listOfGroups}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function createUser(data) {
  return await useJwt
    .post(`${Endpoints.user}`, data)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function getAllUsers(data) {
  let params = ""
  if (data) {
    const { perPage, page } = data
    params += perPage ? `&perPage=${perPage}` : ""
    params += page ? `&page=${page}` : ""
    // params = params ? `?${params}` : ""
  }
  return await useJwt
    .get(`${Endpoints.user}?user_type=employee${params}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function updateUser(data) {
  return await useJwt
    .patch(`${Endpoints.user}/${data.params}`, data.body)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function deleteUser(data) {
  return await useJwt
    .delete(`${Endpoints.user}/${data.params}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function getPlans() {
  return await useJwt
    .get(`${Endpoints.getPlans}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function getAPILimits() {
  return await useJwt
    .get(`${Endpoints.getAPILimits}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function createZoomMeeting(data) {
  return await useJwt
    .post(`${Endpoints.createZoomMeeting}`, data)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function getAllMeetings(data) {
  return await useJwt
    .get(`${Endpoints.getAllMeetings}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function deleteMeeting(data) {
  return await useJwt
    .delete(`${Endpoints.getAllMeetings}/${data}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function integrateZoom(data) {
  return await useJwt
    .post(`${Endpoints.integrateZoom}`, data)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function getZoomIntegration() {
  return await useJwt
    .get(`${Endpoints.integrateZoom}`)
    .then((res) => res.data)
    .catch((err) => err)
}

// DELETE
export async function deleteZoomIntegration(data) {
  return await useJwt
    .delete(`${Endpoints.integrateZoom}`)
    .then((res) => res.data)
    .catch((err) => err)
}

// UPDATE
export async function updateZoomIntegration(data) {
  return await useJwt
    .patch(`${Endpoints.integrateZoom}`, data)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function integrateFo(data) {
  return await useJwt
    .post(`${Endpoints.integrateFo}`, data)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function getFoIntegration() {
  return await useJwt
    .get(`${Endpoints.integrateFo}`)
    .then((res) => res.data)
    .catch((err) => err)
}

// DELETE
export async function deleteFoIntegration(data) {
  return await useJwt
    .delete(`${Endpoints.integrateFo}`)
    .then((res) => res.data)
    .catch((err) => err)
}

// UPDATE
export async function updateFoIntegration(data) {
  return await useJwt
    .patch(`${Endpoints.integrateFo}`, data)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function integrateTeams(data) {
  return await useJwt
    .post(`${Endpoints.integrateTeams}`, data)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function getTeamsIntegration() {
  return await useJwt
    .get(`${Endpoints.integrateTeams}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function updateTeamsIntegration(data) {
  return await useJwt
    .patch(`${Endpoints.integrateTeams}`, data)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function deleteTeamsIntegration(data) {
  return await useJwt
    .delete(`${Endpoints.integrateTeams}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function getStats(data) {
  return await useJwt
    .get(`${Endpoints.graphStats}${data ? `?jobCategory=${data}` : ""}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function getApplicantsStatus(data) {
  return await useJwt
    .get(
      `${Endpoints.graphApplicantStatus}${data ? `?rangeStatus=${data}` : ""}`
    )
    .then((res) => res.data)
    .catch((err) => err)
}

export async function getApplicantsReport(data) {
  return await useJwt
    .get(`${Endpoints.graphApplicantReport}${data ?? ""}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function teamsClientConnect(teamsIntegration) {
  const PCInstance = new PublicClientApplication({
    auth: {
      clientId: teamsIntegration.clientId ?? "",
      authority: process.env.REACT_APP_AUTHORITY,
      redirectUri: teamsIntegration.redirectUrl ?? ""
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: true
    }
  })
  const loginRequest = {
    scopes: ["User.Read", "openid", "profile"],
    redirectUri: teamsIntegration.redirectUrl ?? ""
  }
  await PCInstance.initialize()

  return await PCInstance.loginPopup(loginRequest)
    .then((response) => response)
    .catch((error) => error)
}

export async function createTeamsMeeting(data) {
  return await useJwt
    .post(`${Endpoints.createTeamsMeeting}`, data)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function getLinkedInIntegration() {
  return await useJwt
    .get(`${Endpoints.integrateLinkedIn}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function integrateLinkedIn(data) {
  return await useJwt
    .post(`${Endpoints.integrateLinkedIn}`, data)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function deleteLinkedInIntegration() {
  return await useJwt
    .delete(`${Endpoints.integrateLinkedIn}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function updateLinkedInIntegration(data) {
  return await useJwt
    .patch(`${Endpoints.integrateLinkedIn}`, data)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function fetchLinkedInAccessToken(code) {
  return await useJwt
    .get(`${Endpoints.fetchLinkedInAccessToken}?code=${code}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function LinkedInJobPosting(data) {
  return await useJwt
    .post(`${Endpoints.linkedInJobPost}`, data)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function translateJob(data) {
  return await useJwt
    .post(
      `${Endpoints.getAllJobs}/${data.params}/translateJobDescription`,
      data.body
    )
    .then((res) => res.data)
    .catch((err) => err)
}

export async function generateSkillTest(data) {
  return await useJwt
    .post(`${Endpoints.generateSkillTest}`, data)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function getAuthorizedUris() {
  return await useJwt
    .get(`${Endpoints.AuthorizedUris}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function postAuthorizedUris(data) {
  return await useJwt
    .post(`${Endpoints.AuthorizedUris}`, data)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function updateAuthorizedUris(data) {
  return await useJwt
    .patch(`${Endpoints.AuthorizedUris}/${data.params}`, data.body)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function deleteAuthorizedUris(data) {
  return await useJwt
    .delete(`${Endpoints.AuthorizedUris}/${data.params}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function submitAssessment(data) {
  return await useJwt
    .post(
      `${Endpoints.getSpecificApplicant}/specific/${data.params}/skillAssessment`,
      data.body
    )
    .then((res) => res.data)
    .catch((err) => err)
}

export async function createEvaluation(data) {
  return await useJwt
    .post(`${Endpoints.createEvaluation}`, data)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function getEvaluation(data) {
  return await useJwt
    .get(`${Endpoints.createEvaluation}/specific/${data.params}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function updateEvaluation(data) {
  return await useJwt
    .patch(`${Endpoints.createEvaluation}/specific/${data.params}`, data.body)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function deleteEvaluation(data) {
  return await useJwt
    .delete(`${Endpoints.createEvaluation}/specific/${data.params}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function getAllEvaluation(data) {
  return await useJwt
    .get(`${Endpoints.createEvaluation}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function scheduleOnBoarding(data) {
  return await useJwt
    .post(
      `${Endpoints.scheduleOnBoarding}/${data.applicantId}/interview`,
      data.body
    )
    .then((res) => res.data)
    .catch((err) => err)
}

export async function getInterviewersCall(data) {
  return await useJwt
    .get(`${Endpoints.interviewers}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function submitEvaluationFormCall(data) {
  return await useJwt
    .post(`${Endpoints.submitEvaluationForm}/${data.scheduleId}`, data.body)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function getAssignedEvaluation(data) {
  let params = ""
  if (data) {
    const { perPage, page } = data
    params += perPage ? `perPage=${perPage}` : ""
    params += page ? `&page=${page}` : ""
    params = params ? `?${params}` : ""
  }

  return await useJwt
    .get(`${Endpoints.assignedEvaluation}${params}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function getSpecificAssignedEvaluation(data) {
  return await useJwt
    .get(`${Endpoints.assignedEvaluation}/specific/${data.params}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function cancelInterview(data) {
  return await useJwt
    .patch(
      `${Endpoints.cancelInterview}/applicant/${data.applicantId}/interviews/${data.scheduleId}/interviewStatus`
    )
    .then((res) => res.data)
    .catch((err) => err)
}

export async function rejectInterview(data) {
  return await useJwt
    .patch(
      `${Endpoints.rejectApplicant}/${data.applicantId}/changeApplicantStatus`,
      { status: data.status }
    )
    .then((res) => res.data)
    .catch((err) => err)
}

export async function uploadFile(data) {
  const formData = new FormData()
  formData.append("document", data)
  return useJwt
    .post(Endpoints.uploadOfferLetter, formData)
    .then((res) => res.data)
    .catch((err) => err)
}
export async function sendOfferLetter(data) {
  return await useJwt
    .patch(
      `${Endpoints.sendOfferLetter}/${data.applicantId}/sendOfferLetter`,
      data.body
    )
    .then((res) => res.data)
    .catch((err) => err)
}

export async function offerLetterResponse(data) {
  return await useJwt
    .patch(
      `${Endpoints.rejectApplicant}/${data.applicantId}/applicantOfferResponse`,
      data.body
    )
    .then((res) => res.data)
    .catch((err) => err)
}

export async function getProfileAssessment() {
  return await useJwt
    .get(`${Endpoints.profileAssessment}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function postProfileAssessment(data) {
  return await useJwt
    .post(`${Endpoints.profileAssessment}`, data)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function getProfileAssessmentSubmission(data) {
  return await useJwt
    .get(`${Endpoints.getProfile}/${data}/assessment`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function createAssessmentForms(data) {
  return await useJwt
    .post(`${Endpoints.createBulkAssessment}`, data)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function createAssessmentFormsWithAI(data) {
  return await useJwt
    .post(`${Endpoints.createAssessment}/generate_via_AI`, data)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function createTechnicalTest(data) {
  return await useJwt
    .post(
      `${Endpoints.getSpecificApplicant}/specific/${data.applicantId}/technicalAssessment`,
      data.body
    )
    .then((res) => res.data)
    .catch((err) => err)
}

export async function deleteAssessment(data) {
  return await useJwt
    .delete(`${Endpoints.createAssessment}/specific/${data}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function getAllAssessment(data) {
  let urlParams = ""
  if (data) {
    const {
      skills,
      jobCategories,
      assessmentType,
      questionDifficulty,
      questionType
    } = data

    const params = [
      skills.length > 0 ? `skills=${skills}` : "",
      jobCategories.length > 0 ? `categories=${jobCategories}` : "",
      assessmentType ? `assessmentType=${assessmentType}` : "",
      questionDifficulty ? `questionDifficulty=${questionDifficulty}` : "",
      questionType ? `questionType=${questionType}` : ""
    ]
      .filter(Boolean)
      .join("&")

    urlParams = params ? `?${params}` : ""
  }
  return await useJwt
    .get(`${Endpoints.createAssessment}${data ? urlParams : ""}`)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function isInterviewTestAvailable(data) {
  return await useJwt
    .get(
      `${Endpoints.getSpecificApplicant}/specific/${data}/viewTechnicalAssessmentButton`
    )
    .then((res) => res.data)
    .catch((err) => err)
}

export async function submitInterviewTest(data) {
  return await useJwt
    .post(`${Endpoints.submitInterviewTest}/${data.applicantId}`, data.body)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function updateAssessment(data) {
  return await useJwt
    .patch(`${Endpoints.createAssessment}/specific/${data.params}`, data.body)
    .then((res) => res.data)
    .catch((err) => err)
}

export async function viewInterviewTest(data) {
  return await useJwt
    .get(`${Endpoints.submitInterviewTest}/${data}`)
    .then((res) => res.data)
    .catch((err) => err)
}

// sendDataToFO
export async function sendDataToFO(data) {
  return await useJwt
    .post(`${Endpoints.sendDataToFO}/${data.params}`, data.body)
    .then((res) => res.data)
    .catch((err) => err)
}
