import { createActionTypes } from "@src/utility"

export const COMPANY_PROFILE_TYPES = createActionTypes(
  "COMPANY_PROFILE_TYPES",
  [
    "GET_COMPANY_PROFILE_REQUEST",
    "GET_COMPANY_PROFILE_SUCCESS",
    "GET_COMPANY_PROFILE_FAILED",
    "POST_COMPANY_PROFILE_REQUEST",
    "POST_COMPANY_PROFILE_SUCCESS",
    "POST_COMPANY_PROFILE_FAILED",
    "UPDATE_COMPANY_PROFILE_REQUEST",
    "UPDATE_COMPANY_PROFILE_SUCCESS",
    "UPDATE_COMPANY_PROFILE_FAILED",
    "GET_COMPANY_LOCATIONS_REQUEST",
    "GET_COMPANY_LOCATIONS_SUCCESS",
    "GET_COMPANY_LOCATIONS_FAILED",
    "POST_COMPANY_LOCATIONS_REQUEST",
    "POST_COMPANY_LOCATIONS_SUCCESS",
    "POST_COMPANY_LOCATIONS_FAILED",
    "UPDATE_COMPANY_LOCATIONS_REQUEST",
    "UPDATE_COMPANY_LOCATIONS_SUCCESS",
    "UPDATE_COMPANY_LOCATIONS_FAILED",
    "DELETE_COMPANY_LOCATIONS_REQUEST",
    "DELETE_COMPANY_LOCATIONS_SUCCESS",
    "DELETE_COMPANY_LOCATIONS_FAILED",
    "CLEAR_LOCATIONS_RESPONSE",
    "CLEAR_PROFILE_RESPONSE",
    "UPLOAD_COMPANY_LOGO_REQUEST",
    "UPLOAD_COMPANY_LOGO_SUCCESS",
    "UPLOAD_COMPANY_LOGO_FAILED",
    "API_LIMIT_REQUEST",
    "API_LIMIT_SUCCESS",
    "API_LIMIT_FAILED",
    "ZOOM_INTEGRATION_REQUEST",
    "ZOOM_INTEGRATION_SUCCESS",
    "ZOOM_INTEGRATION_FAILED",
    "GET_ZOOM_INTEGRATION_REQUEST",
    "GET_ZOOM_INTEGRATION_SUCCESS",
    "GET_ZOOM_INTEGRATION_FAILED",
    "DELETE_ZOOM_INTEGRATION_REQUEST",
    "DELETE_ZOOM_INTEGRATION_SUCCESS",
    "DELETE_ZOOM_INTEGRATION_FAILED",
    "UPDATE_ZOOM_INTEGRATION_REQUEST",
    "UPDATE_ZOOM_INTEGRATION_SUCCESS",
    "UPDATE_ZOOM_INTEGRATION_FAILED",
    "LINKEDIN_INTEGRATION_REQUEST",
    "LINKEDIN_INTEGRATION_SUCCESS",
    "LINKEDIN_INTEGRATION_FAILED",
    "GET_LINKEDIN_INTEGRATION_REQUEST",
    "GET_LINKEDIN_INTEGRATION_SUCCESS",
    "GET_LINKEDIN_INTEGRATION_FAILED",
    "UPDATE_LINKEDIN_INTEGRATION_REQUEST",
    "UPDATE_LINKEDIN_INTEGRATION_SUCCESS",
    "UPDATE_LINKEDIN_INTEGRATION_FAILED",
    "DELETE_LINKEDIN_INTEGRATION_REQUEST",
    "DELETE_LINKEDIN_INTEGRATION_SUCCESS",
    "DELETE_LINKEDIN_INTEGRATION_FAILED",

    "TEAMS_INTEGRATION_REQUEST",
    "TEAMS_INTEGRATION_SUCCESS",
    "TEAMS_INTEGRATION_FAILED",
    "GET_TEAMS_INTEGRATION_REQUEST",
    "GET_TEAMS_INTEGRATION_SUCCESS",
    "GET_TEAMS_INTEGRATION_FAILED",
    "DELETE_TEAMS_INTEGRATION_REQUEST",
    "DELETE_TEAMS_INTEGRATION_SUCCESS",
    "DELETE_TEAMS_INTEGRATION_FAILED",
    "UPDATE_TEAMS_INTEGRATION_REQUEST",
    "UPDATE_TEAMS_INTEGRATION_SUCCESS",
    "UPDATE_TEAMS_INTEGRATION_FAILED",

    "FO_INTEGRATION_REQUEST",
    "FO_INTEGRATION_SUCCESS",
    "FO_INTEGRATION_FAILED",
    "GET_FO_INTEGRATION_REQUEST",
    "GET_FO_INTEGRATION_SUCCESS",
    "GET_FO_INTEGRATION_FAILED",
    "DELETE_FO_INTEGRATION_REQUEST",
    "DELETE_FO_INTEGRATION_SUCCESS",
    "DELETE_FO_INTEGRATION_FAILED",
    "UPDATE_FO_INTEGRATION_REQUEST",
    "UPDATE_FO_INTEGRATION_SUCCESS",
    "UPDATE_FO_INTEGRATION_FAILED"
  ]
)
