import { createActionTypes } from "@src/utility"

export const JOBS_ACTION_TYPES = createActionTypes("jobs", [
  "GET_ALL_JOBS_REQUEST",
  "GET_ALL_JOBS_SUCCESS",
  "GET_ALL_JOBS_FAIL",
  "GET_SPECIFIC_JOB_REQUEST",
  "GET_SPECIFIC_JOB_SUCCESS",
  "GET_SPECIFIC_JOB_FAIL",
  "CLEAR_SPECIFIC_JOB",
  "APPLY_FOR_JOB_REQUEST",
  "APPLY_FOR_JOB_SUCCESS",
  "APPLY_FOR_JOB_FAIL",
  "SAVE_JOB_AS_DRAFT_REQUEST",
  "SAVE_JOB_AS_DRAFT_SUCCESS",
  "SAVE_JOB_AS_DRAFT_FAIL",
  "GET_ALL_APPLIED_JOBS_REQUEST",
  "GET_ALL_APPLIED_JOBS_SUCCESS",
  "GET_ALL_APPLIED_JOBS_FAIL",
  "GET_SPECIFIC_APPLIED_JOB_REQUEST",
  "GET_SPECIFIC_APPLIED_JOB_SUCCESS",
  "GET_SPECIFIC_APPLIED_JOB_FAIL",
  "CLEAR_SPECIFIC_APPLIED_JOB",
  "RESET_APPLY_FOR_JOB",
  "CREATE_JOB_REQUEST",
  "CREATE_JOB_SUCCESS",
  "CREATE_JOB_FAIL",
  "RESET_CREATE_JOB",
  "RESET_GET_ALL_JOBS",
  "AUTO_GENERATE_JOB_DESCRIPTION_REQUEST",
  "AUTO_GENERATE_JOB_DESCRIPTION_SUCCESS",
  "AUTO_GENERATE_JOB_DESCRIPTION_FAIL",
  "RESET_AUTO_GENERATE_JOB_DESCRIPTION",
  "APPLICANTS_INFO_REQUEST",
  "APPLICANTS_INFO_SUCCESS",
  "APPLICANTS_INFO_FAIL",
  "CLEAR_JOB_DESCRIPTION",
  "CHANGE_JOB_STATUS_REQUEST",
  "CHANGE_JOB_STATUS_SUCCESS",
  "CHANGE_JOB_STATUS_FAIL",
  "EXCHANGE_ACCESS_TOKEN_REQUEST",
  "EXCHANGE_ACCESS_TOKEN_SUCCESS",
  "EXCHANGE_ACCESS_TOKEN_FAIL",
  "LINKEDIN_JOB_POST_REQUEST",
  "LINKEDIN_JOB_POST_SUCCESS",
  "LINKEDIN_JOB_POST_FAIL",
  "TRANSLATE_JOB_DESCRIPTION_REQUEST",
  "TRANSLATE_JOB_DESCRIPTION_SUCCESS",
  "TRANSLATE_JOB_DESCRIPTION_FAIL",
  "GENERATE_SKILL_TEST_REQUEST",
  "GENERATE_SKILL_TEST_SUCCESS",
  "GENERATE_SKILL_TEST_FAIL",
  "SUBMIT_SKILL_ASSESSMENT_REQUEST",
  "SUBMIT_SKILL_ASSESSMENT_SUCCESS",
  "SUBMIT_SKILL_ASSESSMENT_FAIL",
  "ALL_JOBS_EXPORT_REQUEST",
  "ALL_JOBS_EXPORT_SUCCESS",
  "ALL_JOBS_EXPORT_FAIL",
  "GET_SPECIFIC_APPLICANT_ALL_APPLIED_JOBS_REQUEST",
  "GET_SPECIFIC_APPLICANT_ALL_APPLIED_JOBS_SUCCESS",
  "GET_SPECIFIC_APPLICANT_ALL_APPLIED_JOBS_FAIL",
  "GET_SPECIFIC_APPLICANT_SPECIFIC_APPLIED_JOB_REQUEST",
  "GET_SPECIFIC_APPLICANT_SPECIFIC_APPLIED_JOB_SUCCESS",
  "GET_SPECIFIC_APPLICANT_SPECIFIC_APPLIED_JOB_FAIL",
  "GET_ALL_APPLIED_JOBS_APPLICATION_REQUEST",
  "GET_ALL_APPLIED_JOBS_APPLICATION_SUCCESS",
  "GET_ALL_APPLIED_JOBS_APPLICATION_FAIL",
  "GET_SPECIFIC_APPLIED_JOB_APPLICATION_REQUEST",
  "GET_SPECIFIC_APPLIED_JOB_APPLICATION_SUCCESS",
  "GET_SPECIFIC_APPLIED_JOB_APPLICATION_FAIL",
  "POST_DATA_TO_FO_REQUEST",
  "POST_DATA_TO_FO_SUCCESS",
  "POST_DATA_TO_FO_FAIL",
])

// get all applied jobs request
